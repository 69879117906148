* {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: monospace;
}

.App {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #000000;
  cursor: url('../public/cursor.cur'), auto;
}

.bakgrunn {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.App-header {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Referanser {
  position: absolute;
  width: 100%;
  /* height: 100vmin; */
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;

  @media (max-height: 400px) {
    display: none;
  }

}

.Referanser-kundelogoer {
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: space-evenly;
  max-height: 12em;
  gap: 5px;
}

.Referanser-kundelogoer li {
  cursor: pointer;
  position: relative;
}

.Referanser-kundelogoer li img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;

}

a {
  color: white;
  text-decoration: none;
}

.undertekst {
  font-size: 0.7em;
  padding-bottom: 2em;
  padding-top: 2em;
}

.App-asciilogo {
  font-family: Monospace;
  font-weight: bold;
  align-items: left;
  white-space: pre;
  color: white;
  float: left;
}